import React from 'react';
import Headdemo from '../Subcomponents/Headdemo';
import Expert from '../Subcomponents/HeaderComponents/Expert';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import HeaderMain from '../Subcomponents/HeaderComponents/HeaderMain';
import TrackMain from '../Subcomponents/HeaderComponents/TrackMain';
import "./HomePage.css";
import MobilePage from './MobilePage';
import Phone from '../Subcomponents/Phone';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
// import BloombergData from '../BloombergData/BloombergData';
import { useTranslation } from 'react-i18next';
import PartnershipSlider from '../Subcomponents/DragonSlider/PartnershipSlider';
const HomePage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      {/* <BloombergData /> */}
      <HeaderMain />
      <MobilePage />
      <Expert
        expertHead={t("accordanceTitle")}
        expertpara={t("principlePara")}
      />
      <TrackMain />
      <PartnershipSlider/>
      <Footer />
      <Phone />
    </div>
  )
}
export default HomePage;